import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux';
import { viewTemplate, signContract, signSaved } from '../../actions/pdfManagement';
import { DraggableParent, DraggableItem } from 'react-draggable-playground';
import { toast } from 'react-toastify';
import { bindActionCreators } from 'redux';
import { useHistory, useParams } from 'react-router-dom';
import styles from './styles.module.css'
import SignaturePad from 'react-signature-canvas'
import CustomModal from '../../components/shared/CustomModal';
import moment from "moment-timezone";
import imageCompression from 'browser-image-compression';

const PdfSign = (props) => {
    const history = useHistory()
    let currentDate = new Date()
    // console.log(currentDate, "date")
    const { id } = useParams()
    const [elements, setElements] = useState([])
    // const [elements1, setElements1] = useState([])
    const [quoteElement, setQuoteElement] = useState([])
    const [selectedOption, setSelectedOption] = useState("")
    const [selectedOption2, setSelectedOption2] = useState("")
    const [loading, setLoading] = useState(false)
    const [idxValue, setIdxValue] = useState(null)
    const [flag, setFlag] = useState(null)
    const [key, setKey] = useState(Math.random());
    const [savedSignature, setSavedSignature] = useState(localStorage.getItem(id));
    const [randomNumber, setRandomNumber] = useState(Math.random());
    const [modalDetail, setModalDetail] = useState({
        show: false,
        title: "",
        flag: ""
    });
    let sigPad = {}
    const [pdfData, setPdfData] = useState([])
    const [imageData, setImageData] = useState([])
    const [isActive, setActive] = useState(false);
    const toastId = React.useRef(null)

    // console.log(prevSign, "prev sign")

    const inputvalues = (e, i) => {
        let r = [...elements];
        if (e.target.name == "value") {
            r[i].value = e.target.value;
        }
        setElements(r);
    };
    const clear = () => {
        sigPad.clear()
    }
    // const trim = () => {
    //     setTrimDataUrl(sigPad.getTrimmedCanvas()
    //         .toDataURL('image/png'))
    // }

    const getBase64 = file => {

        return new Promise(resolve => {
            let fileInfo;
            let baseURL = "";
            // Make new FileReader
            let reader = new FileReader();

            // Convert the file to base64 text
            const options = {
                maxSizeMB: 1,
                maxWidthOrHeight: 1920,
                useWebWorker: true
            }
            imageCompression(file, options)
                .then(function (compressedFile) {
                    return reader.readAsDataURL(file); // write your own logic
                })
                .catch(function (error) {
                    console.log(error.message);
                });

            // on reader load somthing...
            reader.onload = () => {
                // Make a fileInfo Object
                baseURL = reader.result;
                let params = {
                    "signature": reader.result,
                    "contractId": id
                }

                props.signSaved(params, res => {
                    setLoading(false);
                    if (res.status) {
                        if (!toast.isActive(toastId.current)) {
                            toastId.current = toast.success(res.message);
                        }
                        localStorage.setItem(`${id}`, reader.result)
                    }
                    else {
                        if (!toast.isActive(toastId.current)) {
                            toastId.current = toast.error(res.message);
                        }
                    }
                },
                    err => {
                        setLoading(false);
                        toast.error("Something went wrong!");
                    })

                let newEle = elements.map(obj => {
                    if (obj.name === "signature") {
                        obj.value = reader.result;
                        obj.sign_status = 1;
                        obj.date_stamp = currentDate;
                    }
                    if (obj.name === "seller_signature") {
                        obj.value = pdfData.sellerDetails.signature;
                        obj.sign_status = 1
                        obj.date_stamp = currentDate;
                    }
                    return obj;
                })
                let quoEle = quoteElement.map(obj => {
                    if (obj.name === "signature") {
                        obj.value = reader.result;
                        obj.sign_status = 1
                        obj.date_stamp = currentDate;
                    }
                    return obj;
                })
                setElements(newEle);
                setQuoteElement(quoEle)
                resolve(baseURL);
            };
        });
    };

    const handleFileInputChange = e => {

        let file = e.target.files[0];
        getBase64(file)
            .then(result => {
                file["base64"] = result;
                if (flag === "term_condition") {
                    setSelectedOption(file.base64)
                }
                else if (flag === "edit_condition") {
                    setSelectedOption(file.base64)
                    setSelectedOption2(file.base64)
                }
                else {
                    setSelectedOption2(file.base64)
                }
                handleOnCloseModal()
            })
            .catch(err => {
            });
    };

    const renderSign = () => {
        return (
            // <div >
            <div className='row pb-4'>
                <div className='col-md-6 col-lg-4'>
                    <div className='leftCon'>

                        {savedSignature ?
                            <div>
                                <h5>Select Previous Signature</h5>
                                <div className='sigRadio' style={{ cursor: "pointer" }} onClick={() => { return handleSelect() }}>
                                    <img className={styles.sigImage}
                                        src={savedSignature} />
                                </div>
                                <div className='text-center mt-3 mb-3'>or</div>
                            </div>
                            : ""
                        }

                        <div>
                            <h5>Upload Signature</h5>
                            <div className='upload_file'>
                                <input type="file" accept="image/*" onChange={handleFileInputChange} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-md-6 col-lg-8 rightCon'>
                    <h5 className='ms-3 mt-2'>Draw your signature</h5>
                    <div className='sign-area'>
                        {/* <div className={styles.sigContainer}> */}
                        <SignaturePad canvasProps={{ className: styles.sigPad }}
                            ref={(ref) => { sigPad = ref }}
                            minWidth={3}
                        />
                        {/* </div> */}
                    </div>

                    {/* {trimmedDataURL
                            ? <div className='signImg'>
                                <img className={styles.sigImage}
                                    src={trimmedDataURL} />
                            </div>
                            : null} */}
                    <div className='modalBtns text-center'>
                        <input type="button" className='cancelBtn m-2' onClick={handleOnCloseModal} value="Cancel" />
                        <button className="clearBtn m-2" onClick={clear}>
                            Clear
                        </button>
                        {/* <button className={styles.buttons.trimBtn} onClick={trim}>
                                Trim
                            </button> */}
                        <input type="button" className='submitBtn m-2' onClick={handleSignSubmit} value="Add Sign" />
                    </div>

                </div>
            </div>
        )
    }

    const handleOnCloseModal = () => {
        setModalDetail({
            show: false,
            title: "",
            flag: ""
        });
        setKey(Math.random());
    }

    const fetchTemplateData = () => {
        let params = {
            _id: id
        }

        setLoading(true);
        props.viewTemplate(params, res => {
            setLoading(false);
            if (res.status) {

                if (res?.data?.data?.Quote?.signedStatus === 1) {
                    history.push('/already-sign')
                }
                setImageData([...res.data.data.Quote.doc_img, ...res.data.data.Template.doc_img])
                setPdfData(res?.data?.data)
                setElements(res?.data?.data?.Template?.elements)
                setQuoteElement(res?.data?.data?.Quote?.elements)
            }
            else {
                if (!toast.isActive(toastId.current)) {
                    toastId.current = toast.error(res.message);
                }
            }
        },
            err => {
                setLoading(false);
                toast.error("Something went wrong!");
            })
    }
    // console.log(elements, "elements")
    const handleSignSubmit = () => {
        const trimmedDataURL = sigPad.getTrimmedCanvas().toDataURL('image/png');
        if (!trimmedDataURL) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Please sign the document");
            }
            return false;
        }
        if (trimmedDataURL.length < 200 && !selectedOption) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Please select the sign or draw the sign!");
            }
            return false;
        }
        setLoading(true);
        setSavedSignature(trimmedDataURL);

        let params = {
            "signature": trimmedDataURL,
            "contractId": id
        }
        if (trimmedDataURL) {
            props.signSaved(params, res => {
                setLoading(false);
                if (res.status) {
                    if (!toast.isActive(toastId.current)) {
                        toastId.current = toast.success(res.message);
                    }
                    handleOnCloseModal()
                    localStorage.setItem(`${id}`, trimmedDataURL)
                }
                else {
                    if (!toast.isActive(toastId.current)) {
                        toastId.current = toast.error(res.message);
                    }
                    setSelectedOption("")
                    setSelectedOption2("")
                }
            },
                err => {
                    setLoading(false);
                    setSelectedOption("")
                    setSelectedOption2("")
                    toast.error("Something went wrong!");
                    handleOnCloseModal()
                })
        }
        if (flag === "term_condition") {
            setSelectedOption(trimmedDataURL)
        }
        else if (flag === "edit_condition") {
            setSelectedOption(trimmedDataURL)
            setSelectedOption2(trimmedDataURL)
        }
        else {
            setSelectedOption2(trimmedDataURL)
        }

        if (flag === "term_condition" || flag === "quote_condition" || flag === "edit_condition") {
            let termElements = elements.map(obj => {

                if (obj.name === "signature") {
                    obj.value = trimmedDataURL;
                    obj.sign_status = 1
                    obj.date_stamp = currentDate;
                }
                if (obj.name === "seller_signature") {
                    obj.value = pdfData.sellerDetails.signature;
                    obj.sign_status = 1
                    obj.date_stamp = currentDate;
                }
                return obj;
            })
            setElements(termElements);
            let quoteElements = quoteElement.map(obj => {
                if (obj.name === "signature") {
                    obj.value = trimmedDataURL;
                    obj.sign_status = 1
                    obj.date_stamp = currentDate;
                }
                return obj;
            })
            setQuoteElement(quoteElements)
            handleOnCloseModal()
        }
    }

    // let signValue = elements.find(element => element.type === "signature");
    // let quoValue = quoteElement.find(element => element.type === "signature")
    // console.log(quoteElement, "quoelemenets")

    const handleSubmit = () => {
        if (selectedOption2 === "") {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Please sign the Quotation");
            }
        }
        else if (selectedOption === "") {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Please sign the Terms & Condition");
            }
        }
        else {
            let params = {
                contractId: id,
                elements: elements,
                quote_elements: quoteElement,
                token: props.auth.token
            }
            setLoading(true);
            props.signContract(params, res => {
                setLoading(false);
                if (res.status) {
                    if (!toast.isActive(toastId.current)) {
                        toastId.current = toast.success("Contract signed successfully!");
                    }
                    history.push('/thank-you');
                }
                else {
                    if (!toast.isActive(toastId.current)) {
                        toastId.current = toast.error(res.message);
                    }
                }
            },
                err => {
                    setLoading(false);
                    toast.error("Something went wrong!");
                })
        }
    }

    const handleSelect = () => {
        if (flag === "term_condition") {
            setSelectedOption(savedSignature ? savedSignature : pdfData?.signatures[0]?.signature)
            let newEle = elements.map(obj => {
                if (obj.name === "signature") {
                    obj.value = savedSignature ? savedSignature : pdfData?.signatures[0]?.signature;
                    obj.sign_status = 1
                    obj.date_stamp = currentDate;
                }
                if (obj.name === "seller_signature") {
                    obj.value = pdfData.sellerDetails.signature;
                    obj.sign_status = 1
                    obj.date_stamp = currentDate;
                }
                return obj;
            })

            let quoEle = quoteElement.map(obj => {
                if (obj.name === "signature") {
                    obj.value = savedSignature ? savedSignature : pdfData?.signatures[0]?.signature;
                    obj.sign_status = 1
                    obj.date_stamp = currentDate;
                }
                return obj;
            })

            setElements(newEle);
            setQuoteElement(quoEle)
            handleOnCloseModal()
            setRandomNumber(Math.random());
        }
        else if (flag === "edit_condition") {
            setSelectedOption(savedSignature ? savedSignature : pdfData?.signatures[0]?.signature)
            setSelectedOption2(savedSignature ? savedSignature : pdfData?.signatures[0]?.signature)
            let newEle = elements.map(obj => {
                if (obj.name === "signature") {
                    obj.value = savedSignature ? savedSignature : pdfData?.signatures[0]?.signature;
                    obj.sign_status = 1
                    obj.date_stamp = currentDate;
                }
                if (obj.name === "seller_signature") {
                    obj.value = pdfData.sellerDetails.signature;
                    obj.sign_status = 1
                    obj.date_stamp = currentDate;
                }
                return obj;
            })

            let quoEle = quoteElement.map(obj => {
                if (obj.name === "signature") {
                    obj.value = savedSignature ? savedSignature : pdfData?.signatures[0]?.signature;
                    obj.sign_status = 1
                    obj.date_stamp = currentDate;
                }
                return obj;
            })

            setElements(newEle);
            setQuoteElement(quoEle)
            handleOnCloseModal()
            setRandomNumber(Math.random());
        }
        else {
            setSelectedOption2(savedSignature ? savedSignature : pdfData?.signatures[0]?.signature)
            let newEle = elements.map(obj => {
                if (obj.name === "signature") {
                    obj.value = savedSignature ? savedSignature : pdfData?.signatures[0]?.signature;
                    obj.sign_status = 1
                    obj.date_stamp = currentDate;
                }
                if (obj.name === "seller_signature" && obj.value == "") {
                    obj.value = pdfData.sellerDetails.signature;
                    obj.sign_status = 1
                    obj.date_stamp = currentDate;
                }
                return obj;
            })

            let quoEle = quoteElement.map(obj => {
                if (obj.name === "signature") {
                    obj.value = savedSignature ? savedSignature : pdfData?.signatures[0]?.signature;
                    obj.sign_status = 1
                    obj.date_stamp = currentDate;
                }
                return obj;
            })

            setElements(newEle);
            setQuoteElement(quoEle)
            handleOnCloseModal()
            setRandomNumber(Math.random());
        }

    }

    const modalOpen = (flag) => {
        setModalDetail({ show: true, flag: 'sign_document', title: 'Sign Document' });
        setKey(Math.random())
        // setIdxValue(idx)
        setFlag(flag)
    }

    useEffect(() => {
        fetchTemplateData()
    }, [])

    return (
        <>
            <div className='wrapper'>
                {loading ?
                    <>
                        <div className="row">
                            <div className="col-sm-12">
                                <div className='custom_loader'>

                                    <div className="loader ">
                                        <i className="loader-el"></i>
                                        <i className="loader-el"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                    :
                    <div className="container">
                        {loading ?
                            <div className="loder__  mt-2 mb-3">
                                <div className="spinnerfalse-grow" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                            </div>
                            :
                            <div className='text-center mb-4 mt-3'>
                                <input
                                    type="button"
                                    className='nextBtn'
                                    value="Submit Signatures"
                                    style={{ marginLeft: '150px', width: '210px', height: '60px', background: '#38cd73' }}
                                    onClick={() => handleSubmit()}
                                />
                            </div>
                        }
                        <div className='row mr-0'>
                            <div className='col-12'>
                                {(selectedOption || selectedOption2) && <div className='text-center mb-4 mt-3'>
                                    <input
                                        type="button"
                                        className='nextBtn'
                                        value="Edit Signatures"
                                        onClick={() => modalOpen("edit_condition")}
                                    />
                                </div>}
                                {/* <div className='card card_top'> */}
                                <DraggableParent className="drag_box" enableuserselecthack="true" cancel=".just-name" style={{ width: "1400px" }}>
                                    {imageData.length > 0 ?
                                        imageData.map((val, index) => {
                                            return (
                                                <div id={`image_no_${index + 1}`} key={index}>
                                                    <img src={val?.file_link} alt="img" style={{ pointerEvents: "none", maxWidth: "100%" }} />
                                                </div>
                                            )
                                        })
                                        :
                                        <></>
                                    }

                                    <div>
                                        {elements?.length > 0 ?
                                            elements.map((row, idx) => {
                                                const prviousPageNumber = pdfData.Quote.doc_img.length + (Number(row?.page_number) - 1);
                                                // let yPosition = (1994.31 * prviousPageNumber) + (53 * prviousPageNumber) + row.y_position;
                                                let xPosition = row.x_position_web;
                                                // if(row.name == 'signature'){
                                                //     yPosition = yPosition + 30
                                                // }

                                                let yPosition = imageData.length > 0 ? ((1985.3125 * prviousPageNumber) + row.y_position_web) : 0;

                                                if (row.name == 'state') {
                                                    // yPosition = yPosition - 4
                                                    xPosition = xPosition * 3.2;
                                                }

                                                if (row.name == 'county') {
                                                    xPosition = xPosition * 4;
                                                }

                                                if (row.name == 'footerLine') {
                                                    xPosition = xPosition * 1.2;
                                                }

                                                if (row.name == 'phone_no') {
                                                    xPosition = xPosition * 0.8;
                                                }
                                                // console.log(prviousPageNumber, "======prviousPageNumber");
                                                return (
                                                    <React.Fragment key={idx}>
                                                        <div className={(row.name == 'footerLine' || row.name == 'phone_no') ? "centered-footer" : null}>
                                                            <DraggableItem
                                                                defaultPosition={{
                                                                    x: xPosition / 2 - 150,
                                                                    // y: row?.page_number === "1" ? (
                                                                    //     (1751 * pdfData.Quote.doc_img.length)
                                                                    //     +
                                                                    //     (6 * pdfData.Quote.doc_img.length) + row.y_position
                                                                    // ) :
                                                                    //     (
                                                                    //         (1753 * pdfData.Quote.doc_img.length)
                                                                    //         +
                                                                    //         (6 * pdfData.Quote.doc_img.length) + row.y_position + 1751
                                                                    //     )
                                                                    y: yPosition
                                                                }}
                                                                disabled
                                                                className="center_footer" style={{ width: "100%" }}
                                                            >
                                                                {({ }) => {
                                                                    return (
                                                                        <>
                                                                            {
                                                                                row.type === "text" ?
                                                                                    <div className='centered'>
                                                                                        <div className={(row.name === "state" || row.name === "county") ? "pdf_state" : ""}>
                                                                                            {/* <input id={"element_" + row.key} autoComplete='off' name="value" type="text" value={row.value} onChange={(e) => inputvalues(e, idx)} onFocus={(e) => inputvalues(e, idx)} style={{ height: `${row.element_x}px`, width: `${row.element_y}px`, border: "0", fontWeight: 700, color: "#000000", background: 'none' }} placeholder="" autoFocus disabled /> */}
                                                                                            <p id={"element_" + row.key}>{row.value}</p>
                                                                                        </div>
                                                                                    </div>
                                                                                    : row.name === "signature" ?
                                                                                        <div
                                                                                            onClick={
                                                                                                () => {
                                                                                                    !selectedOption2 ?
                                                                                                        modalOpen("term_condition") :
                                                                                                        setSelectedOption(selectedOption2)
                                                                                                }}>
                                                                                            {selectedOption ?
                                                                                                <>
                                                                                                    <img
                                                                                                        className={`${styles.sigImage} border-0`}
                                                                                                        style={{ height: `${row.element_x}px`, width: `${row.element_y}px`, objectFit: 'contain' }}
                                                                                                        src={selectedOption}
                                                                                                    />
                                                                                                    <span className='d-block'>{moment(currentDate).format('MM/DD/YYYY')} {" "}|{" "} {moment(currentDate).format('hh:mm A')}</span>
                                                                                                </>
                                                                                                :
                                                                                                <div className='signature' style={{ height: `${row.element_x}`, width: `${row.element_y}` }}>
                                                                                                    <label className='mb-0 py-1 ps-1'><i className="fa fa-upload" aria-hidden="true"></i> &nbsp;Signature</label>
                                                                                                </div>
                                                                                            }
                                                                                        </div>
                                                                                        : row.type === "fullname" ?
                                                                                            <div className='signature' style={{ height: `${row.element_x}`, width: `${row.element_y}` }}>
                                                                                                <input id={"element_" + row.key} autoComplete='off' name="value" type="text" value={row.value} onChange={(e) => inputvalues(e, idx)} onFocus={(e) => inputvalues(e, idx)} style={{ height: `${row.element_x}px`, width: `${row.element_y}px`, border: "0", fontWeight: 700, color: "#000000" }} placeholder="" disabled autoFocus />
                                                                                            </div>
                                                                                            : row.name === "seller_signature" ?
                                                                                                <div style={{ marginLeft: '800px' }}>
                                                                                                    <img
                                                                                                        className={`${styles.sigImage} border-0`}
                                                                                                        style={{ height: `${row.element_x + 15}px`, width: `${row.element_y + 15}px`, objectFit: 'contain', background: 'transparent', marginTop: '-25px' }}
                                                                                                        src={row.value}
                                                                                                    />
                                                                                                    <span className='d-block'>{moment(currentDate).format('MM/DD/YYYY')} {" "}|{" "} {moment(currentDate).format('hh:mm A')}</span>
                                                                                                </div> :
                                                                                                <></>
                                                                            }
                                                                        </>
                                                                    )
                                                                }}
                                                            </DraggableItem>
                                                        </div>

                                                    </React.Fragment>
                                                )
                                            })
                                            :
                                            <></>
                                        }
                                    </div>

                                    <div>
                                        {quoteElement?.length > 0 ?
                                            quoteElement.map((row, idx) => {

                                                const prviousPageNumber = pdfData.Quote.doc_img.length - 1;
                                                // console.log(prviousPageNumber, "=======prviousPageNumber");
                                                // let yPosition = (1985.3125 * prviousPageNumber) + (53 * prviousPageNumber) + (row.y_position_web / 3.87);
                                                let yPosition = (1985.3125 * prviousPageNumber) + ((row.y_position_web * 28.225) / 100) - 105;  // 25
                                                //  28.225 = (draw_box div width * 100) / page_width
                                                // let yPosition = 3970.625 + (1525/4);
                                                return (
                                                    <React.Fragment key={idx}>
                                                        <DraggableItem
                                                            // defaultPosition={{
                                                            //     x: row?.x_position,
                                                            // y: row?.page_number === "1" ? (
                                                            //     (1751 * (pdfData.Quote.doc_img.length - 1))
                                                            //     +
                                                            //     (6 * pdfData.Quote.doc_img.length) + row.y_position
                                                            // ) :
                                                            //     (
                                                            //         (1753 * pdfData.Quote.doc_img.length)
                                                            //         +
                                                            //         (6 * pdfData.Quote.doc_img.length) + row.y_position + 1751
                                                            //     )
                                                            // }}
                                                            defaultPosition={{
                                                                x: row?.x_position_web ? row.x_position_web - 100 : 0,
                                                                // y: row?.page_number === "1" ? (
                                                                //     (1985 * (pdfData.Quote.doc_img.length - 1))
                                                                //     +
                                                                //     (6 * pdfData.Quote.doc_img.length) + row.y_position
                                                                // )/4 :
                                                                //     (
                                                                //         (1985 * pdfData.Quote.doc_img.length)
                                                                //          + row.y_position + 1985
                                                                //     )/4
                                                                y: yPosition + 75
                                                            }}
                                                            disabled
                                                            className={isActive ? 'react-draggable-dra' : null} style={{ top: '0', left: "0", background: 'red' }}
                                                        >
                                                            {({ }) => {
                                                                return (
                                                                    <>
                                                                        {
                                                                            row.type === "text" ?
                                                                                <div>
                                                                                    <input id={"element_" + row.key} autoComplete='off' name="value" type="text" value={row.value} onChange={(e) => inputvalues(e, idx)} onFocus={(e) => inputvalues(e, idx)} style={{ height: `${row.element_x}px`, width: `${row.element_y}px`, border: "0", fontWeight: 700, color: "#000000", background: 'none' }} placeholder="" autoFocus disabled />
                                                                                </div>
                                                                                : row.name === "signature" ?
                                                                                    <div
                                                                                        onClick={
                                                                                            () => {
                                                                                                !selectedOption ?
                                                                                                    modalOpen("quote_condition") :
                                                                                                    setSelectedOption2(selectedOption)
                                                                                            }}>
                                                                                        {selectedOption2 ?
                                                                                            <>
                                                                                                <img
                                                                                                    className={`${styles.sigImage} border-0 quate`}
                                                                                                    style={{ height: `${row.element_x}px`, width: `${row.element_y}px`, objectFit: 'contain' }}
                                                                                                    src={selectedOption2}
                                                                                                />
                                                                                                <span className='d-block'>{moment(currentDate).format('MM/DD/YYYY')} {" "}|{" "} {moment(currentDate).format('hh:mm A')}</span>
                                                                                            </>
                                                                                            :
                                                                                            <div className='signature' style={{ height: `${row.element_x}`, width: `${row.element_y}` }}>
                                                                                                <label className='mb-0 py-1 ps-1'><i className="fa fa-upload" aria-hidden="true"></i> &nbsp;Signature</label>
                                                                                            </div>
                                                                                        }

                                                                                    </div>
                                                                                    : row.type === "fullname" ?
                                                                                        <div className='signature' style={{ height: `${row.element_x}`, width: `${row.element_y}` }}>
                                                                                            <input id={"element_" + row.key} autoComplete='off' name="value" type="text" value={row.value} onChange={(e) => inputvalues(e, idx)} onFocus={(e) => inputvalues(e, idx)} style={{ height: `${row.element_x}px`, width: `${row.element_y}px`, border: "0", fontWeight: 700, color: "#000000" }} placeholder="" disabled autoFocus />
                                                                                        </div>
                                                                                        : row.name === "seller_signature" ?
                                                                                            <div>
                                                                                                <img
                                                                                                    className={`${styles.sigImage} border-0`}
                                                                                                    style={{ height: `${row.element_x + 15}px`, width: `${row.element_y + 15}px`, objectFit: 'contain', background: 'transparent' }}
                                                                                                    src={row.value}
                                                                                                />
                                                                                                <span className='d-block'>{moment(currentDate).format('MM/DD/YYYY')} {" "}|{" "} {moment(currentDate).format('hh:mm A')}</span>
                                                                                            </div>
                                                                                            :
                                                                                            <></>
                                                                        }
                                                                    </>
                                                                )
                                                            }}
                                                        </DraggableItem>
                                                    </React.Fragment>
                                                )
                                            })
                                            :
                                            <></>
                                        }
                                    </div>

                                </DraggableParent>
                                {/* </div> */}
                            </div>
                        </div>

                        {loading ?
                            <div className="loder__  mt-2 mb-3">
                                <div className="spinnerfalse-grow" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                            </div>
                            :
                            <div className='text-center mb-4 mt-3'>
                                <input
                                    type="button"
                                    className='nextBtn'
                                    value="Submit Signatures"
                                    style={{ marginLeft: '150px', width: '210px', height: '60px', background: '#38cd73' }}
                                    onClick={() => handleSubmit()}
                                />
                            </div>
                        }
                    </div>
                }
            </div>
            <CustomModal
                key={key}
                show={modalDetail.show}
                // backdrop="static"
                showCloseBtn={true}
                ids={modalDetail.flag == "sign_document" ? 'signDocument' : ''}
                isRightSideModal={true}
                child={modalDetail.flag == 'sign_document' ?
                    renderSign()
                    :
                    ""
                }
                size="lg"
                title={modalDetail.title}
                subTitle="(Either select the signature or draw your signature below)"
                onCloseModal={() => handleOnCloseModal()}
            />
        </>
    )
}

const mapStateToProps = state => ({
    auth: state.auth,
    pdf: state.pdf
});
const mapDispatchToProps = dispatch => ({
    viewTemplate: bindActionCreators(viewTemplate, dispatch),
    signContract: bindActionCreators(signContract, dispatch),
    signSaved: bindActionCreators(signSaved, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(PdfSign);