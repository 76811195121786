import { CCard, CCardGroup } from "@coreui/react";
import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import {
  getProduct,
  deleteProduct,
  getTemplateData,
  updateTemplate,
} from "../../actions/pdfManagement";
import { bindActionCreators } from "redux";
import editIcon from "../../public/images/editIcon.png";
import deleteIcon from "../../public/images/deleteIcon.png";
import Swal from "sweetalert2";
import backArrow from "../../public/images/backArrow.svg";
import slider1 from "../../public/images/slider1.png";
import dummyy from "../../public/images/dummyImg.svg";
import plusIcon from "../../public/images/plusIcon.svg";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import EditTemplate from "./EditTemplate";
import { deleteSingleLineItem } from "../../actions/manageTemplate";
import AddNewLineItem from "./AddNewLineItem";
const ViewTemplate = (props) => {
  const location = useLocation();

  const [jobTitle, setJobTitle] = useState("");
  const [title, setTitle] = useState("");
  const [editItemId, setEditItemId] = useState("");
  const [oldData, setOldData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [templateId, setTemplateId] = useState("");
  const [editTitle, setEditTitle] = useState(false);
  const [editTemplateName, setEditTemplateName] = useState(false);
  const history = useHistory();
  const productId = location ? location?.state?.productId : "";

  const [showAdd, setShowAdd] = useState(false);

  const [data, setData] = useState([]);

  const getAllTemplateData = () => {
    let params = {
      token: props.auth.token,
      id: productId,
    };
    props.getTemplateData(
      params,
      (res) => {
        setLoading(false);
        if (res.status) {
          console.log(res, "response of data get");
          setTemplateId(res.data.data._id);
          setJobTitle(res.data.data.jobTitle);
          setTitle(res.data.data.title);
          setOldData(res.data.data.jobDetails);
          // setData(res.data.data.jobDetails)
          const newData = res.data.data.jobDetails;
          const updatedNewData = newData.map((item) => {
            const formattedPrice = Number(item.unitPrice).toFixed(2);
            const [integerPart, decimalPart] = formattedPrice.split(".");
            const priceWithTrailingZeros =
              decimalPart.length === 1
                ? `${integerPart}.${decimalPart}0`
                : formattedPrice;

            const formattedTotalPrice = Number(item.totalPrice).toFixed(2);
            const [integerPartTotal, decimalPartTotal] =
              formattedTotalPrice.split(".");
            const totalPriceWithTrailingZeroes =
              decimalPartTotal.length === 1
                ? `${integerPartTotal}.${decimalPartTotal}0`
                : formattedTotalPrice;
            return {
              ...item,
              isActive: false,
              unitPrice: priceWithTrailingZeros,
              totalPrice: totalPriceWithTrailingZeroes,
            };
          });
          setData(updatedNewData);
        } else {
        }
      },
      (err) => {
        setLoading(false);
        toast.error("Something went wrong!");
      }
    );
  };

  const handleDeleteSingleItem = (productId, e) => {
    e.preventDefault();
    Swal.fire({
      title: "Are you sure?",
      text: "You are about to delete this product!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        // If the user confirms, proceed with the delete operation

        const updatedOldData = data.filter((item) => item._id !== productId);
        console.log(updatedOldData, "updated old data");

        for (const job of updatedOldData) {
          if (job.mitreStyles) {
            for (const mitre of job.mitreStyles) {
              delete mitre._id;
            }
          }
          if (job.hangerTypes) {
            for (const hanger of job.hangerTypes) {
              delete hanger._id;
            }
          }
          if (job.categoryType == "service") {
            delete job.quantity;
            delete job.colorName;
            delete job.colorId;
            delete job.materialId;
            delete job.materialName;
            delete job.sizeName;
            delete job.subCategoryName;
            delete job.subCategorySlug;
            delete job.colorName;
          }
          delete job._id;
          delete job.isActive;
        }

        let params = {
          templateId: templateId,
          title: title,
          jobTitle: jobTitle,
          jobDetails: updatedOldData,
          token: props.auth.token,
        };

        props.updateTemplate(
          params,
          (res) => {
            setLoading(false);
            if (res.status) {
              console.log("Updated ------------------------");
              // setEditTitle(false)
              toast.success("Line item deleted successfully");
              getAllTemplateData();
            } else {
            }
          },
          (err) => {
            setLoading(false);
            toast.error("Something went wrong!");
          }
        );
      }
    });
  };

  const handleDeleteTemplate = (productId, e) => {
    e.preventDefault();
    Swal.fire({
      title: "Are you sure?",
      text: "You are about to delete this product!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        let params = {
          id: productId,
          token: props.auth.token,
        };
        props.deleteSingleLineItem(
          params,
          (res) => {
            setLoading(false);
            if (res.status) {
              toast.success("Deleted successfully");
              location.state?.prevPage ? history.push({ pathname: '/manage-template', state: location.state?.prevPage }) : history.goBack()
              // setEditItemId("")
            } else {
            }
          },
          (err) => {
            setLoading(false);
            console.log(err, "error when api not integrate");
            toast.error("Something went wrong!");
          }
        );
      }
    });
  };
  const handleEditProduct = (index) => {
    const updatedData = [...data];
    // Update the isActive field of the object at the specified index
    updatedData[index].isActive = true;

    // Update the state with the modified newData
    setData(updatedData);
  };

  const saveEditTemplate = (data) => {
    for (const job of oldData) {
      if (job.mitreStyles) {
        for (const mitre of job.mitreStyles) {
          delete mitre._id;
        }
      }
      if (job.hangerTypes) {
        for (const hanger of job.hangerTypes) {
          delete hanger._id;
        }
      }
      if (job.categoryType == "service") {
        delete job.quantity;
        delete job.colorName;
        delete job.colorId;
        delete job.materialId;
        delete job.materialName;
        delete job.sizeName;
        delete job.subCategoryName;
        delete job.subCategorySlug;
        delete job.colorName;
      }
      delete job._id;
    }
    let params = {
      templateId: templateId,
      title: title,
      jobTitle: jobTitle,
      jobDetails: oldData,
      token: props.auth.token,
    };

    props.updateTemplate(
      params,
      (res) => {
        setLoading(false);
        if (res.status) {
          console.log("Updated ------------------------");
          setEditTitle(false);
          setEditTemplateName(false);
        } else {
        }
      },
      (err) => {
        setLoading(false);
        toast.error("Something went wrong!");
      }
    );
  };

  const handleEditTitle = () => {
    setEditTitle(true);
  };

  useEffect(() => {
    if (data.unitPrice) {
      const formattedPrice = Number(data.unitPrice).toFixed(2);
      const [integerPart, decimalPart] = formattedPrice.split(".");
      const priceWithTrailingZeros =
        decimalPart.length === 1
          ? `${integerPart}.${decimalPart}0`
          : formattedPrice;
      setData((prev) => ({
        ...prev,
        unitPrice: priceWithTrailingZeros,
      }));
    }
  }, [data.price]);

  useEffect(() => {
    getAllTemplateData();
  }, [productId, editItemId]);

  return (
    <div>
      <div className="content-wrapper">
        <div className="customWrapper">
          <div className="customHeader">
            <>
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <div
                    className="backArrow p-0"
                    onClick={() => {
                      location.state?.prevPage ? history.push({ pathname: '/manage-template', state: location.state?.prevPage }) : history.goBack()
                    }}
                  >
                    {" "}
                    <img src={backArrow} alt="" className="img-fluid me-2" />
                    Back
                  </div>
                  <h4 className="heading ps-0 pt-0">Template Details</h4>
                </div>
                <button
                  onClick={(data) => saveEditTemplate(data)}
                  disabled={!editTitle && !editTemplateName}
                  className={`${editTitle || editTemplateName ? "saveBtn" : "disableSave"
                    } saveBtn  m-0`}
                >
                  Save Template
                </button>
              </div>
            </>
          </div>
          <div className="innerContent">
            <div className="aluminumCon">
              {editTemplateName ?
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control customFormCtrl"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        style={{ width: '250%' }}
                      />
                    </div>
                  </div>
                </div> :
                <div className="aluminumCon">
                  <h4 className="uploadHeading">{title}</h4>
                  <span onClick={() => setEditTemplateName(true)}>
                    <img src={editIcon} alt="" class="m-3" />
                  </span>
                </div>}

              <div className="editDeleteBtn">
                <span onClick={(e) => handleDeleteTemplate(templateId, e)}>
                  <img src={deleteIcon} alt="" class="" />
                </span>
              </div>
            </div>
            {editTitle ? (
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control customFormCtrl"
                      value={jobTitle}
                      onChange={(e) => setJobTitle(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <div className="aluminumCon commonHeading mt-3">
                <p className="">{jobTitle}</p>
                <span onClick={handleEditTitle}>
                  <img src={editIcon} alt="" class="me-3" />
                </span>
              </div>
            )}

            {data.map((item, index) => (
              <>
                {" "}
                {editItemId == item._id ? (
                  <EditTemplate
                    productId={productId}
                    id={item._id}
                    show={(e) => setEditItemId(e)}
                    templateId={templateId}
                    saveEditTemplate={saveEditTemplate}
                    title={title}
                    jobTitle={jobTitle}
                  />
                ) : (
                  <div className="innerComonCard">
                    <div className="d-flex header">
                      <div>
                        <h1 className="heading20 mb-0">{item.categoryName}</h1>
                        <p className="subHeadingGray mb-0">Product/Service</p>
                      </div>
                      <div className="editDeleteBtn">
                        <span
                          onClick={() => {
                            if (editItemId !== item._id) {
                              setEditItemId(item._id);
                              handleEditProduct(index);
                            } else {
                              setEditItemId(null);
                            }
                          }}
                        >
                          <img src={editIcon} alt="" class="me-3" />
                        </span>
                        {oldData.length > 1 && <span
                          onClick={(e) => handleDeleteSingleItem(item._id, e)}
                        >
                          <img src={deleteIcon} alt="" class="" />
                        </span>}
                      </div>
                    </div>

                    <p className="text mt-3">{item.description}</p>
                    {item.categorySlug == "gutter_cleaning" ||
                      item.categorySlug == "other" ? (
                      ""
                    ) : (
                      <>
                        <h1 className="heading20 mb-0">
                          {item.subCategoryName !== null
                            ? item.subCategoryName
                            : "N/A"}
                        </h1>
                        <p className="subHeadingGray mb-0">Type</p>
                      </>
                    )}

                    <div className="row">
                      {item.categorySlug == "gutter_cleaning" ||
                        item.categorySlug == "other" ? (
                        ""
                      ) : (
                        <>
                          <div className="col-md-6">
                            <div className="halfRound mt-4">
                              <h1 className="heading16 mb-0">Size :</h1>
                              <p className="text mb-0">
                                {item.sizeName !== null ? item.sizeName : "N/A"}
                              </p>
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="halfRound mt-4">
                              <h1 className="heading16 mb-0">Qty :</h1>
                              <p className="text mb-0">{item.quantity}</p>
                            </div>
                          </div>
                        </>
                      )}

                      <div className="col-md-6">
                        <div className="halfRound mt-4">
                          <h1 className="heading16 mb-0">Unit Price :</h1>
                          <p className="text mb-0">${item.unitPrice}</p>
                        </div>
                      </div>
                      {item.categorySlug == "gutter_cleaning" ||
                        item.categorySlug == "other" ? (
                        ""
                      ) : (
                        <>
                          <div className="col-md-6">
                            <div className="halfRound mt-4">
                              <h1 className="heading16 mb-0">Color/Finish :</h1>
                              <p className="text mb-0">
                                {item.colorName !== null
                                  ? item.colorName
                                  : "N/A"}
                              </p>
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="halfRound mt-4">
                              <h1 className="heading16 mb-0">Material :</h1>
                              <p className="text mb-0">
                                {item.materialName !== null
                                  ? item.materialName
                                  : "N/A"}
                              </p>
                            </div>
                          </div>
                        </>
                      )}
                      {/* {item.categorySlug == "gutter_cleaning" || item.categorySlug == "other" || item.categorySlug == "gutter_covers" || item.mitreStyles.length > 0 ? "" :
                                         
                                        } */}
                      {item.categorySlug === "gutters" ? (
                        <>
                          <div className="row mt-4">
                            {item.mitreStyles.map((mitre, index) => (
                              <>
                                <div className="commonHeading mt-4">
                                  <p className="">Mitres</p>
                                </div>
                                <div className="col-md-6">
                                  <div className="commonMiters">
                                    <h1 className="heading20 mb-3">
                                      {mitre.mitreLabel}
                                    </h1>
                                    <div className="d-flex justify-content-between">
                                      <div className="commonMitersInner">
                                        <h1 className="heading16 mb-0">
                                          Type :
                                        </h1>
                                        <p className="subHeadingGray mb-0">
                                          {mitre.mitreValue}
                                        </p>
                                      </div>
                                      <div className="commonMitersInner">
                                        <h1 className="heading16 mb-0">
                                          Count :
                                        </h1>
                                        <p className="subHeadingGray mb-0">
                                          {mitre.mitreCount}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </>
                            ))}
                            {item.hangerTypes.map((hanger, index) => (
                              <>
                                <div className="commonHeading mt-4">
                                  {item.hangerTypes.length > 0 ? (
                                    <p className="">
                                      Hangar {index > 0 ? index : ""}
                                    </p>
                                  ) : (
                                    <p className="">Hangar</p>
                                  )}
                                </div>
                                <div className="row">
                                  <div className="col-md-6">
                                    <div className="commonMiters">
                                      {item.hangerTypes.length > 0 ? (
                                        <h1 className="heading20 mb-3">
                                          Hangar {index > 0 ? index : ""}
                                        </h1>
                                      ) : (
                                        <h1 className="heading20 mb-3">
                                          Hangar
                                        </h1>
                                      )}

                                      <div className="d-flex justify-content-between">
                                        <div className="commonMitersInner">
                                          <h1 className="heading16 mb-0">
                                            Type :
                                          </h1>
                                          <p className="subHeadingGray mb-0">
                                            {hanger.hangerName}
                                          </p>
                                        </div>
                                        <div className="commonMitersInner">
                                          <h1 className="heading16 mb-0">
                                            Count :
                                          </h1>
                                          <p className="subHeadingGray mb-0">
                                            {hanger.hangerCount}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </>
                            ))}
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                      {item.categorySlug === "downspouts" ? (
                        <div className="row">
                          <>
                            <div className="col-md-6">
                              <div className="commonMiters">
                                {/* <h1 className='heading20 mb-3'>Hangar</h1> */}
                                <div className="d-flex justify-content-between">
                                  <div className="commonMitersInner">
                                    <h1 className="heading16 mb-0">Type :</h1>
                                    <p className="subHeadingGray mb-0">
                                      A Elbows
                                    </p>
                                  </div>
                                  <div className="commonMitersInner">
                                    <h1 className="heading16 mb-0">Qty :</h1>
                                    <p className="subHeadingGray mb-0">
                                      {item.elbowType.a}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="commonMiters">
                                {/* <h1 className='heading20 mb-3'>Hangar</h1> */}
                                <div className="d-flex justify-content-between">
                                  <div className="commonMitersInner">
                                    <h1 className="heading16 mb-0">Type :</h1>
                                    <p className="subHeadingGray mb-0">
                                      B Elbows
                                    </p>
                                  </div>
                                  <div className="commonMitersInner">
                                    <h1 className="heading16 mb-0">Qty :</h1>
                                    <p className="subHeadingGray mb-0">
                                      {item.elbowType.b}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="commonMiters">
                                {/* <h1 className='heading20 mb-3'>Hangar</h1> */}
                                <div className="d-flex justify-content-between">
                                  <div className="commonMitersInner">
                                    <h1 className="heading16 mb-0">Type :</h1>
                                    <p className="subHeadingGray mb-0">
                                      C Elbows
                                    </p>
                                  </div>
                                  <div className="commonMitersInner">
                                    <h1 className="heading16 mb-0">Qty :</h1>
                                    <p className="subHeadingGray mb-0">
                                      {item.elbowType.c}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        </div>
                      ) : (
                        ""
                      )}

                      <div>
                        {item.photos.length === 0 ? (
                          ""
                        ) : (
                          <>
                            <div className="commonHeading mt-4">
                              <p className="">Images</p>
                            </div>
                            <div className="row my-4">
                              <div className="col-md-12">
                                <div className="edit_profile mb-3 d-flex flex-wrap">
                                  {item.photos.map((val, index) => (
                                    <div className="uploadedData addTempData me-3 mb-3">
                                      <div className="mitersImg">
                                        <img
                                          src={val}
                                          alt=""
                                          className="miterDummy m-0"
                                        />
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                        <div className="totalPrice px-0">
                          <p>Total </p>
                          <span>${item.totalPrice}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </>
            ))}
            {showAdd ? (
              <AddNewLineItem
                templateId={templateId}
                title={title}
                jobTitle={jobTitle}
                oldData={oldData}
              />
            ) : (
              <div className="col-lg-12 mb-3 mt-5">
                <button
                  onClick={(e) => { if (data.length == 5) { toast.error("You can't add more than 5 line items per template.") } else { setShowAdd(true) } }}
                  className="addLineBtn"
                >
                  <img src={plusIcon} alt="" className="img-fluid me-2" />
                  Add Line Item
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  getTemplateData: bindActionCreators(getTemplateData, dispatch),
  deleteSingleLineItem: bindActionCreators(deleteSingleLineItem, dispatch),
  updateTemplate: bindActionCreators(updateTemplate, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewTemplate);
