if (process.env.NODE_ENV === "production") {
    module.exports = {
        API_URL: 'https://stag-gutter.itechnolabs.tech',
        DEV_URL: 'https://stag-gutter.itechnolabs.tech'
        // API_URL: 'https://dev-gutter.itechnolabs.tech',
        // DEV_URL: 'https://dev-gutter.itechnolabs.tech'
        // DEV_URL: 'https://api.gutterapplication.com',
        // API_URL: 'https://api.gutterapplication.com',
    };
} else {
    module.exports = {
        // API_URL: 'https://stag-gutter.itechnolabs.tech',
        // DEV_URL: 'https://stag-gutter.itechnolabs.tech'
        API_URL: 'https://dev-gutter.itechnolabs.tech',
        DEV_URL: 'https://dev-gutter.itechnolabs.tech',
        // DEV_URL: 'https://api.gutterapplication.com',
        // API_URL: 'https://api.gutterapplication.com',
    };
}
